exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-article-js": () => import("./../../../src/components/article.js" /* webpackChunkName: "component---src-components-article-js" */),
  "component---src-components-article-listing-js": () => import("./../../../src/components/articleListing.js" /* webpackChunkName: "component---src-components-article-listing-js" */),
  "component---src-components-list-js": () => import("./../../../src/components/list.js" /* webpackChunkName: "component---src-components-list-js" */),
  "component---src-components-review-js": () => import("./../../../src/components/review.js" /* webpackChunkName: "component---src-components-review-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lists-js": () => import("./../../../src/pages/lists.js" /* webpackChunkName: "component---src-pages-lists-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-safety-nicotine-js": () => import("./../../../src/pages/safety/nicotine.js" /* webpackChunkName: "component---src-pages-safety-nicotine-js" */)
}

